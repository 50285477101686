import * as React from "react";
import { Link } from "gatsby-theme-material-ui";
import { StaticImage } from "gatsby-plugin-image";
import Box from '@mui/material/Box';
import Layout from "../components/layout";
import Seo from "../components/seo";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ChartLayout from "../components/ChartStructure"
import LifeNumber from "../components/LifeNumber"
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import { useTheme, createTheme } from '@mui/material/styles';
import { useState } from 'react'
import DatePicker from '../components/DatePicker'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { NumberChip as Chip } from '../components/Chip';
import MobileNumber from "../components/MobileNumber"
import { East } from '@mui/icons-material/';
import NumberChart from "../components/NumberChart"
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/styles'
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Parse from '../vendor/Parse'
import PostList from '../components/PostList'
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Menu from '../components/Menu'
const { useEffect } = React;


const myTheme = createTheme({

})
const IndexPage = ({ children }) => {

    const [value, setValue] = React.useState(
        dayjs('1988-09-14T12:00:01'),
    );
    const [post, setPosts] = useState([])
    const [preview, setPreview] = useState('')
    const [number, setNumber] = useState('')
    const [day, setDate] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [elements, setElements] = useState(null)
    const [accordion, setAccordion] = useState([])
    const theme = useTheme();
    const [numberInfo, setNumberInfo] = useState()
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const handleChange = (newValue) => {
        console.log(newValue)
        setValue(newValue);
    };


    const onNumber = (result) => {
        setNumberInfo(result)
    }

    useEffect(async () => {
        const result = await Parse.Cloud.run('getPost', {

            category: 'sdffsd',

        })
        console.log(result)
        setPosts(result.map((e) => e.toJSON()))
    }, [])



    var refdiv = React.useRef(null);
    var rte;





    return (
        <Grid container>
            <Grid item xs={12} md={4} padding="0 32px 0 32px">
                <h3>Numerology Tools For Health</h3>
                <Menu />
            </Grid>
            <Grid item xs={12} md={4}>
                {
                    children
                }
            </Grid>
            <Grid item xs={12} md={4}>
                <Box display={"flex"} flexDirection={"column"} alignItems={"end"} padding="0 32px 0 32px">
                    <h3>Numbers And TCM</h3>
                    <Divider />
                    {
                        post.map(e => <a href="">
                            {
                                e?.data?.title
                            }
                        </a>)
                    }
                </Box>

            </Grid>
        </Grid>)
}

export default IndexPage
